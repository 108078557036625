@tailwind base;
@tailwind components;
@tailwind utilities;

/*@font-face {*/
/*    font-family: "Poppins";*/
/*    src: local("Poppins"),*/
/*    url("https://fonts.cdnfonts.com/s/16009/Poppins-Regular.woff") format("truetype");*/
/*    font-weight: normal;*/
/*}*/

@font-face {
    font-family: 'Poppins';
    src: url('https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGTucA.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

body {
    background: #f0f0f0 !important;
}

[type='button'].ms-Button--primary {
    background-color: rgb(0, 120, 212)
}

[type='button'].ms-Button--primary:hover {
    background-color: rgb(16, 110, 190);
    border: 1px solid rgb(16, 110, 190);
    color: rgb(255, 255, 255);
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}